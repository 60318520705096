<template>
  <div class="project-unit-lawyer-allocation-update">
    <fd-form @submit="onFormSubmit">
      <modal-content>
        <template #body>
          <!-- Unit Info -->
          <div class="p-1 md-p-3">
            <unit-summary :unit="unit" class="bordered-card p-3"></unit-summary>
          </div>

          <!-- Assign Lawyer -->
          <AssignLawyerToUnit
            ref="assignLawyer"
            v-model="lawyers"
            isEdit
            required
            :type="type"
            class="p-1 md-p-3"
            :projectId="projectId"
            :projectUnitId="unit.id"
            :mapValue="(val) => val.id"
          ></AssignLawyerToUnit>
        </template>

        <template #footer>
          <div class="d-flex justify-content-end p-2">
            <fd-button v-if="!isLoading" class="medium" @click="$emit('close')">
              Close
            </fd-button>
            <fd-button
              type="submit"
              class="medium main ml-2"
              :disabled="isLoading"
              :isLoading="isLoading"
              loadingEnabled
              icon="fas fa-edit"
            >
              Update
            </fd-button>
          </div>
        </template>
      </modal-content>
    </fd-form>
  </div>
</template>

<script>
import ManagerRoles from "@/modules/Project/classes/ManagerRoles";
import ProjectUnitAPI from "@/modules/Project/api/projectUnit";

export default {
  components: {
    AssignLawyerToUnit: () =>
      import("@/modules/Project/components/AssignLawyers/AssignToUnit"),
    UnitSummary: () => import("@/modules/Project/components/Unit/UnitSummary")
  },
  mixins: [],
  props: {
    type: {
      type: String,
      validator: (val) => new ManagerRoles().getRoles().includes(val)
    },
    projectId: {
      type: [Number, String],
      required: true
    },
    unit: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      projectUnitAPI: new ProjectUnitAPI(this.type),

      lawyers: {},

      isLoading: false
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    async onFormSubmit() {
      try {
        this.isLoading = true;
        await this.projectUnitAPI.updateAssignedLawyers(
          this.unit.id,
          this.lawyers
        );
        this.isLoading = false;
        this.$notify({
          group: "alert",
          type: "success",
          title: "Update Success",
          text: "Unit lawyer allocation has been update successfully."
        });

        await this.$refs.assignLawyer.loadLawyersAssigned();
      } catch (error) {
        this.isLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text:
            "Failed to update unit lawyer allocation. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
